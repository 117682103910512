import {getBannerApi,getBannerPictureApi} from '@/api/index.js';

export async function getBannerList(code){
  let result = []
 await getBannerApi({code,pageSize:999}).then(async res=>{
    const banner = res.data.data.list
    const pid = Array.from(new Set(res.data.data.list.map(el=>el.pid)))
     await getBannerPictureApi({pageSize:999}).then(res=>{
        const pidStatus = []
        pid.forEach(el=>{
           const temp = res.data.data.list.filter(item=>{
              if(item.id == el){
               return item
              }
            })
            if(temp[0].status == 1){
              return  pidStatus.push(temp[0].id)
            }
        })
        pidStatus.forEach(el=>{
          const temp = banner.filter(item=>{
            if(item.pid == el){
              return item
            }
          })
           const bannerShow = temp.filter(el=>{
              if(el.status == 1){
                return el
              }
            })
            const jieguo =  bannerShow.map((el,index)=>{
              return {
                key:el.id,
                cover:el.imageUrl,
                label: el.name,
              }
            })
            result.push(...jieguo)
        })

      })
    })
    result = result.map((el,index)=>{

      return {
        ...el,
        id:index+1
      }
    })
  return result
}

const list = [
  {
    name:"智能虫情测报仪ST-CQ101",
    path:"/special/CQ101"
  },
  {
    name:"智能高空虫情测报仪ST-GK101",
    path:"/special/GK101"
  },
  {
    name:"光伏自动气象测报站ST-X101",
    path:"/special/x101"
  },
  {
    name:"智能水肥机",
    path:"/special/SF101"
  },
  {
    name:"太阳能墒情仪ST-XJ101",
    path:"/special/SQ101"
  },
  {
    name:"智能孢子捕捉仪ST-BZ101",
    path:"/special/BZ101"
  },
  {
    name:"太阳能捕虫器ST-FX101",
    path:"/special/fx101"
  },
  {
    name:"太阳能捕虫器ST-FX301",
    path:"/special/fx301"
  },
  {
    name:"太阳能路灯捕虫器ST-LD/FX",
    path:"/special/stld"
  },
  {
    name:"智能巡检无人车ST-XJ101",
    path:"/special/XJ101"
  },
  {
    name:"全地形大负载多功能无人车ST-TK101",
    path:"/special/TK101"
  },
  {
    name:"智能巡检无人机",
    path:"/special/UAV"
  },
  {
    name:"多功能无人机",
    path:"/special/MultifunctionalUAV"
  },
  {
    name:"生物有机肥",
    path:"/special/yg101"
  }
]
export function getMore(text){
  let path = ''
  list.forEach(el=>{
    if(el.name.includes(text)){
      path= el.path
    }
  } )
  return path
}
